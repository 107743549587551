import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";

const About = () => {
  const { contentfulHoitolanKuvaus } = useStaticQuery(graphql`
    {
      contentfulHoitolanKuvaus {
        id
        header
        body {
          raw
        }
      }
    }
  `);

  return (
    <section className="about">
      <h2>{contentfulHoitolanKuvaus.header}</h2>
      {renderRichText(contentfulHoitolanKuvaus.body)}
      {/* <p>
        Kauneuden ilo on rento ja hyvän mielen hoitola, joka tarjoaa
        monipuoliset kauneudenhoitopalvelut joustavin ajoin. Asiakastyytyväisyys
        sekä tulosten saavuttaminen hoidoissa on meille erityisen tärkeää ja
        siksi kouluttaudummekin jatkuvasti lisää, jotta ammattitaitomme ja
        hoitovalikoimamme pysyy jatkuvasti kehittyvän kauneusalan mukana.
      </p>
      <p>
        Kasvohoidoissa käytössä laadukas hedelmähappoihin perustuva
        ihonhoitosarja{" "}
        <a href="https://www.exuviance.fi/" target="_blank" rel="noreferrer">
          Exuviance professional
        </a>
        . Myynnissä laaja valikoima Exuviance kotihoitotuotteita.
      </p> */}
    </section>
  );
};

export default About;
